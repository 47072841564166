import React from 'react';

import {connect} from 'react-redux'

//Parents: ProjectContainer

const Project = (props) => {

    return (
        <>
        <div className="shop-page">
            <div className="text">
                <h1>Coming Soon!</h1>
            </div>
        </div>
        </>
    )
};

export default connect()(Project);